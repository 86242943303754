import { useState, useReducer } from "react";
import Settings from "./components/Settings";
import Display from "./components/Display";

const LOCAL_STORAGE_KEY = "wallpaperTime";

const initialState = {
    morning: {
        hours: 0,
        minutes: 0
    },
    evening: {
        hours: 0,
        minutes: 0
    }
};

function timeSettings(state, action) {
    switch (action.type) {
        case "setMorning":
            return {
                ...state,
                morning: {
                    ...state.morning,
                    ...action.time
                }
            };
        case "setEvening":
            return {
                ...state,
                evening: {
                    ...state.evening,
                    ...action.time
                }
            };
        default:
            throw new Error("no action given for updating state");
    }
}

function getSavedSettings(state) {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || state;
}

function App() {
    const [showSettings, setShowSettings] = useState(true);
    const [state, dispatch] = useReducer(
        timeSettings,
        initialState,
        getSavedSettings
    );

    return (
        <div className="App">
            {showSettings && (
                <Settings
                    state={state}
                    dispatch={dispatch}
                    setShowSettings={setShowSettings}
                />
            )}
            <Display state={state} />
        </div>
    );
}

export default App;
