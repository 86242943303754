import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as Sleep } from "../images/sleep.svg";
import { ReactComponent as Awake } from "../images/awake.svg";

const DisplayStyled = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${props => props.isNight ? "#000" : "#00ffd0"};
    fill: ${props => props.isNight ? "#fff" : "#000"};

    .emoji {
        display: flex;
        flex: 1 1 40%;
        margin: 4rem;
    }

    .time {
        display: flex;
        flex: 1 1 auto;
        justify-content: center;
        align-items: center;
        color: ${props => props.isNight ? "#fff" : "#000"};
        font-size: 4rem;
        font-weight: bold;
    }
`;

const Display = ({ state }) => {
    const [time, setTime] = useState();

    useEffect(() => {
        const timer = setInterval(() => {
            const date = new Date();
            const hours =
                date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
            const minutes =
                date.getMinutes() < 10
                    ? `0${date.getMinutes()}`
                    : date.getMinutes();
            const seconds =
                date.getSeconds() < 10
                    ? `0${date.getSeconds()}`
                    : date.getSeconds();
            const ident = date.getHours() > 11 ? "PM" : "AM";

            setTime(`${hours}:${minutes}:${seconds} ${ident}`);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    const currentDate = new Date();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();

    const eveningComparison = hours >= state.evening.hours;
    const morningComparison = hours <= state.morning.hours;
    let isNight = eveningComparison || morningComparison;

    if (hours === parseInt(state.evening.hours)) {
        isNight = minutes >= state.evening.minutes
    }

    if (hours === parseInt(state.morning.hours)) {
        isNight = minutes <= state.morning.minutes
    }


    return (
        <DisplayStyled isNight={isNight}>
            {isNight && <Sleep className="emoji" />}
            {!isNight && <Awake className="emoji" />}
            <div className="time">{time}</div>
        </DisplayStyled>
    );
};

export default Display;
