import {
    createGlobalStyle
} from 'styled-components';
const GlobalStyle = createGlobalStyle `
    :focus {
        box-shadow: 0 0 1px 3px black;
        outline: none;
    }

    .App {
        display: flex;
        flex: 1 0 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        button {
            padding: 0.8rem 1.6rem;
            color: white;;
            background-color: #5f5fda;
            border: 2px solid #5f5fd8;
            border-radius: 1.4rem;
        }
    }
`;

export default GlobalStyle;
