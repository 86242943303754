import React, { useState, useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import TimeSelect from "./TimeSelect";

const LOCAL_STORAGE_KEY = "wallpaperTime";

const SettingsStyled = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: calc(50% - ${(props) => parseInt(props.height) / 2}px);
    left: calc(50% - ${(props) => parseInt(props.width) / 2}px);

    padding: 2rem;
    border-radius: 1.4rem;
    box-shadow: 0 0 1rem -0.1rem black;

    background-color: white;

    form {
        margin-bottom: 1.8rem;
    }
`;

const Settings = ({ dispatch, state, setShowSettings }) => {
    const [dimensions, setDimensions] = useState({ height: 0, width: 0 });
    const ref = useRef();

    useLayoutEffect(() => {
        setDimensions({
            height: ref.current.clientHeight,
            width: ref.current.clientWidth
        });
    }, []);


    function submitHandler(e) {
        e.preventDefault();
        setShowSettings(false);
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
    }

    return (
        <SettingsStyled
            ref={ref}
            height={dimensions.height}
            width={dimensions.width}
        >
            <form onSubmit={submitHandler}>
                <TimeSelect
                    childKey="Morning"
                    onChange={(value) =>
                        dispatch({
                            type: "setMorning",
                            time: value
                        })
                    }
                    hours={state.morning.hours}
                    minutes={state.morning.minutes}
                    ranges={{
                        hourStart: 0,
                        hourEnd: 12
                    }}
                >
                    Morning:
                </TimeSelect>
                <TimeSelect
                    childKey="Evening"
                    onChange={(value) =>
                        dispatch({
                            type: "setEvening",
                            time: value
                        })
                    }
                    hours={state.evening.hours}
                    minutes={state.evening.minutes}
                    ranges={{
                        hourStart: 13,
                        hourEnd: 23
                    }}
                >
                    Evening:
                </TimeSelect>
            </form>
            <button onClick={submitHandler}>Save Time</button>
        </SettingsStyled>
    );
};

export default Settings;
