import React from "react";
import styled from "styled-components";

const Label = styled.label`
    display: flex;
    margin-bottom: .8rem;
    justify-content: center;
    align-items: center;

    select {
        padding: .4rem;
        margin: .4rem;
        border-radius: .15rem;
    }

`;

const TimeSelect = ({ children, onChange, childKey, hours, minutes, ranges = {} }) => {
    function generateOptions(range, key) {
        return range.map((item) => (
            <option key={`${childKey}-${key}-${item}`}>{item}</option>
        ));
    }

    function generateRange(startRange, endRange, step = 1) {
        const range = [];
        let i;

        for (i = startRange; i <= endRange; i = i + step) {
            range.push(i);
        }

        return range;
    }

    function generateHoursOptions() {
        const { hourStart, hourEnd } = ranges;
        const KEY = "Hours";
        const range = generateRange(hourStart, hourEnd);

        return generateOptions(range, KEY);
    }

    function generateMinutesOptions() {
        const KEY = "Minutes";
        const range = generateRange(0, 59, 15);

        return generateOptions(range, KEY);
    }

    return (
        <Label>
            {children}
            <select
                onChange={(e) => onChange({ hours: e.target.value })}
                value={hours}
            >
                {generateHoursOptions()}
            </select>
            <select
                onChange={(e) => onChange({ minutes: e.target.value })}
                value={minutes}
            >
                {generateMinutesOptions()}
            </select>
        </Label>
    );
};

export default TimeSelect;
